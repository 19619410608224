import { default as cookiesHWfhH3FGVLMeta } from "/codebuild/output/src2698016701/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/cookies.vue?macro=true";
import { default as cookiesD4PppD0lsOMeta } from "/codebuild/output/src2698016701/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/cookies.vue?macro=true";
import { default as index6Ykk9thrPCMeta } from "/codebuild/output/src2698016701/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/index.vue?macro=true";
import { default as legalvC4UfOVBSHMeta } from "/codebuild/output/src2698016701/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/legal.vue?macro=true";
import { default as portfolio_45about6AWBDWviFrMeta } from "/codebuild/output/src2698016701/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-about.vue?macro=true";
import { default as portfolio_45fees7SAPPE1BddMeta } from "/codebuild/output/src2698016701/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-fees.vue?macro=true";
import { default as portfolio_45investors5yc2BYH4L3Meta } from "/codebuild/output/src2698016701/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-investors.vue?macro=true";
import { default as portfolio_45issuersLnOHlXE7ajMeta } from "/codebuild/output/src2698016701/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-issuers.vue?macro=true";
import { default as portfolio_45membersgvoWV7YfuRMeta } from "/codebuild/output/src2698016701/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-members.vue?macro=true";
import { default as index7YOiduqj4AMeta } from "/codebuild/output/src2698016701/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-news/index.vue?macro=true";
import { default as transformation_45of_45an_45edav_45into_45a_45reitDb2p5ezSEyMeta } from "/codebuild/output/src2698016701/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-news/transformation-of-an-edav-into-a-reit.vue?macro=true";
import { default as portfolio_45partnersOb7dzPAoabMeta } from "/codebuild/output/src2698016701/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-partners.vue?macro=true";
import { default as privacyfkCKjngjeaMeta } from "/codebuild/output/src2698016701/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/privacy.vue?macro=true";
import { default as indexx8hj3mC37sMeta } from "/codebuild/output/src2698016701/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/index.vue?macro=true";
import { default as legalLbOnFncEEgMeta } from "/codebuild/output/src2698016701/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/legal.vue?macro=true";
import { default as portfolio_45aboutX6v4XwydxEMeta } from "/codebuild/output/src2698016701/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-about.vue?macro=true";
import { default as portfolio_45feesBhcAskPJjcMeta } from "/codebuild/output/src2698016701/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-fees.vue?macro=true";
import { default as portfolio_45investors0ih0PUXJO0Meta } from "/codebuild/output/src2698016701/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-investors.vue?macro=true";
import { default as portfolio_45issuers0wReEQIC3cMeta } from "/codebuild/output/src2698016701/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-issuers.vue?macro=true";
import { default as portfolio_45memberswtHs6PPzRiMeta } from "/codebuild/output/src2698016701/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-members.vue?macro=true";
import { default as indexWH9tXEprd8Meta } from "/codebuild/output/src2698016701/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-news/index.vue?macro=true";
import { default as transformation_45of_45an_45edav_45into_45a_45reit2qDn15QQGjMeta } from "/codebuild/output/src2698016701/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-news/transformation-of-an-edav-into-a-reit.vue?macro=true";
import { default as portfolio_45partnersEVPgULgvniMeta } from "/codebuild/output/src2698016701/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-partners.vue?macro=true";
import { default as privacyhC7eVhJHReMeta } from "/codebuild/output/src2698016701/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/privacy.vue?macro=true";
export default [
  {
    name: cookiesHWfhH3FGVLMeta?.name ?? "cookies",
    path: cookiesHWfhH3FGVLMeta?.path ?? "/cookies",
    meta: cookiesHWfhH3FGVLMeta || {},
    alias: cookiesHWfhH3FGVLMeta?.alias || [],
    redirect: cookiesHWfhH3FGVLMeta?.redirect,
    component: () => import("/codebuild/output/src2698016701/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/cookies.vue").then(m => m.default || m)
  },
  {
    name: cookiesD4PppD0lsOMeta?.name ?? "es-cookies",
    path: cookiesD4PppD0lsOMeta?.path ?? "/es/cookies",
    meta: cookiesD4PppD0lsOMeta || {},
    alias: cookiesD4PppD0lsOMeta?.alias || [],
    redirect: cookiesD4PppD0lsOMeta?.redirect,
    component: () => import("/codebuild/output/src2698016701/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/cookies.vue").then(m => m.default || m)
  },
  {
    name: index6Ykk9thrPCMeta?.name ?? "es",
    path: index6Ykk9thrPCMeta?.path ?? "/es",
    meta: index6Ykk9thrPCMeta || {},
    alias: index6Ykk9thrPCMeta?.alias || [],
    redirect: index6Ykk9thrPCMeta?.redirect,
    component: () => import("/codebuild/output/src2698016701/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/index.vue").then(m => m.default || m)
  },
  {
    name: legalvC4UfOVBSHMeta?.name ?? "es-legal",
    path: legalvC4UfOVBSHMeta?.path ?? "/es/legal",
    meta: legalvC4UfOVBSHMeta || {},
    alias: legalvC4UfOVBSHMeta?.alias || [],
    redirect: legalvC4UfOVBSHMeta?.redirect,
    component: () => import("/codebuild/output/src2698016701/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/legal.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45about6AWBDWviFrMeta?.name ?? "es-portfolio-about",
    path: portfolio_45about6AWBDWviFrMeta?.path ?? "/es/portfolio-about",
    meta: portfolio_45about6AWBDWviFrMeta || {},
    alias: portfolio_45about6AWBDWviFrMeta?.alias || [],
    redirect: portfolio_45about6AWBDWviFrMeta?.redirect,
    component: () => import("/codebuild/output/src2698016701/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-about.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45fees7SAPPE1BddMeta?.name ?? "es-portfolio-fees",
    path: portfolio_45fees7SAPPE1BddMeta?.path ?? "/es/portfolio-fees",
    meta: portfolio_45fees7SAPPE1BddMeta || {},
    alias: portfolio_45fees7SAPPE1BddMeta?.alias || [],
    redirect: portfolio_45fees7SAPPE1BddMeta?.redirect,
    component: () => import("/codebuild/output/src2698016701/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-fees.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45investors5yc2BYH4L3Meta?.name ?? "es-portfolio-investors",
    path: portfolio_45investors5yc2BYH4L3Meta?.path ?? "/es/portfolio-investors",
    meta: portfolio_45investors5yc2BYH4L3Meta || {},
    alias: portfolio_45investors5yc2BYH4L3Meta?.alias || [],
    redirect: portfolio_45investors5yc2BYH4L3Meta?.redirect,
    component: () => import("/codebuild/output/src2698016701/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-investors.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45issuersLnOHlXE7ajMeta?.name ?? "es-portfolio-issuers",
    path: portfolio_45issuersLnOHlXE7ajMeta?.path ?? "/es/portfolio-issuers",
    meta: portfolio_45issuersLnOHlXE7ajMeta || {},
    alias: portfolio_45issuersLnOHlXE7ajMeta?.alias || [],
    redirect: portfolio_45issuersLnOHlXE7ajMeta?.redirect,
    component: () => import("/codebuild/output/src2698016701/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-issuers.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45membersgvoWV7YfuRMeta?.name ?? "es-portfolio-members",
    path: portfolio_45membersgvoWV7YfuRMeta?.path ?? "/es/portfolio-members",
    meta: portfolio_45membersgvoWV7YfuRMeta || {},
    alias: portfolio_45membersgvoWV7YfuRMeta?.alias || [],
    redirect: portfolio_45membersgvoWV7YfuRMeta?.redirect,
    component: () => import("/codebuild/output/src2698016701/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-members.vue").then(m => m.default || m)
  },
  {
    name: index7YOiduqj4AMeta?.name ?? "es-portfolio-news",
    path: index7YOiduqj4AMeta?.path ?? "/es/portfolio-news",
    meta: index7YOiduqj4AMeta || {},
    alias: index7YOiduqj4AMeta?.alias || [],
    redirect: index7YOiduqj4AMeta?.redirect,
    component: () => import("/codebuild/output/src2698016701/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-news/index.vue").then(m => m.default || m)
  },
  {
    name: transformation_45of_45an_45edav_45into_45a_45reitDb2p5ezSEyMeta?.name ?? "es-portfolio-news-transformation-of-an-edav-into-a-reit",
    path: transformation_45of_45an_45edav_45into_45a_45reitDb2p5ezSEyMeta?.path ?? "/es/portfolio-news/transformation-of-an-edav-into-a-reit",
    meta: transformation_45of_45an_45edav_45into_45a_45reitDb2p5ezSEyMeta || {},
    alias: transformation_45of_45an_45edav_45into_45a_45reitDb2p5ezSEyMeta?.alias || [],
    redirect: transformation_45of_45an_45edav_45into_45a_45reitDb2p5ezSEyMeta?.redirect,
    component: () => import("/codebuild/output/src2698016701/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-news/transformation-of-an-edav-into-a-reit.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45partnersOb7dzPAoabMeta?.name ?? "es-portfolio-partners",
    path: portfolio_45partnersOb7dzPAoabMeta?.path ?? "/es/portfolio-partners",
    meta: portfolio_45partnersOb7dzPAoabMeta || {},
    alias: portfolio_45partnersOb7dzPAoabMeta?.alias || [],
    redirect: portfolio_45partnersOb7dzPAoabMeta?.redirect,
    component: () => import("/codebuild/output/src2698016701/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-partners.vue").then(m => m.default || m)
  },
  {
    name: privacyfkCKjngjeaMeta?.name ?? "es-privacy",
    path: privacyfkCKjngjeaMeta?.path ?? "/es/privacy",
    meta: privacyfkCKjngjeaMeta || {},
    alias: privacyfkCKjngjeaMeta?.alias || [],
    redirect: privacyfkCKjngjeaMeta?.redirect,
    component: () => import("/codebuild/output/src2698016701/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/privacy.vue").then(m => m.default || m)
  },
  {
    name: indexx8hj3mC37sMeta?.name ?? "index",
    path: indexx8hj3mC37sMeta?.path ?? "/",
    meta: indexx8hj3mC37sMeta || {},
    alias: indexx8hj3mC37sMeta?.alias || [],
    redirect: indexx8hj3mC37sMeta?.redirect,
    component: () => import("/codebuild/output/src2698016701/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: legalLbOnFncEEgMeta?.name ?? "legal",
    path: legalLbOnFncEEgMeta?.path ?? "/legal",
    meta: legalLbOnFncEEgMeta || {},
    alias: legalLbOnFncEEgMeta?.alias || [],
    redirect: legalLbOnFncEEgMeta?.redirect,
    component: () => import("/codebuild/output/src2698016701/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/legal.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45aboutX6v4XwydxEMeta?.name ?? "portfolio-about",
    path: portfolio_45aboutX6v4XwydxEMeta?.path ?? "/portfolio-about",
    meta: portfolio_45aboutX6v4XwydxEMeta || {},
    alias: portfolio_45aboutX6v4XwydxEMeta?.alias || [],
    redirect: portfolio_45aboutX6v4XwydxEMeta?.redirect,
    component: () => import("/codebuild/output/src2698016701/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-about.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45feesBhcAskPJjcMeta?.name ?? "portfolio-fees",
    path: portfolio_45feesBhcAskPJjcMeta?.path ?? "/portfolio-fees",
    meta: portfolio_45feesBhcAskPJjcMeta || {},
    alias: portfolio_45feesBhcAskPJjcMeta?.alias || [],
    redirect: portfolio_45feesBhcAskPJjcMeta?.redirect,
    component: () => import("/codebuild/output/src2698016701/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-fees.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45investors0ih0PUXJO0Meta?.name ?? "portfolio-investors",
    path: portfolio_45investors0ih0PUXJO0Meta?.path ?? "/portfolio-investors",
    meta: portfolio_45investors0ih0PUXJO0Meta || {},
    alias: portfolio_45investors0ih0PUXJO0Meta?.alias || [],
    redirect: portfolio_45investors0ih0PUXJO0Meta?.redirect,
    component: () => import("/codebuild/output/src2698016701/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-investors.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45issuers0wReEQIC3cMeta?.name ?? "portfolio-issuers",
    path: portfolio_45issuers0wReEQIC3cMeta?.path ?? "/portfolio-issuers",
    meta: portfolio_45issuers0wReEQIC3cMeta || {},
    alias: portfolio_45issuers0wReEQIC3cMeta?.alias || [],
    redirect: portfolio_45issuers0wReEQIC3cMeta?.redirect,
    component: () => import("/codebuild/output/src2698016701/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-issuers.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45memberswtHs6PPzRiMeta?.name ?? "portfolio-members",
    path: portfolio_45memberswtHs6PPzRiMeta?.path ?? "/portfolio-members",
    meta: portfolio_45memberswtHs6PPzRiMeta || {},
    alias: portfolio_45memberswtHs6PPzRiMeta?.alias || [],
    redirect: portfolio_45memberswtHs6PPzRiMeta?.redirect,
    component: () => import("/codebuild/output/src2698016701/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-members.vue").then(m => m.default || m)
  },
  {
    name: indexWH9tXEprd8Meta?.name ?? "portfolio-news",
    path: indexWH9tXEprd8Meta?.path ?? "/portfolio-news",
    meta: indexWH9tXEprd8Meta || {},
    alias: indexWH9tXEprd8Meta?.alias || [],
    redirect: indexWH9tXEprd8Meta?.redirect,
    component: () => import("/codebuild/output/src2698016701/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-news/index.vue").then(m => m.default || m)
  },
  {
    name: transformation_45of_45an_45edav_45into_45a_45reit2qDn15QQGjMeta?.name ?? "portfolio-news-transformation-of-an-edav-into-a-reit",
    path: transformation_45of_45an_45edav_45into_45a_45reit2qDn15QQGjMeta?.path ?? "/portfolio-news/transformation-of-an-edav-into-a-reit",
    meta: transformation_45of_45an_45edav_45into_45a_45reit2qDn15QQGjMeta || {},
    alias: transformation_45of_45an_45edav_45into_45a_45reit2qDn15QQGjMeta?.alias || [],
    redirect: transformation_45of_45an_45edav_45into_45a_45reit2qDn15QQGjMeta?.redirect,
    component: () => import("/codebuild/output/src2698016701/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-news/transformation-of-an-edav-into-a-reit.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45partnersEVPgULgvniMeta?.name ?? "portfolio-partners",
    path: portfolio_45partnersEVPgULgvniMeta?.path ?? "/portfolio-partners",
    meta: portfolio_45partnersEVPgULgvniMeta || {},
    alias: portfolio_45partnersEVPgULgvniMeta?.alias || [],
    redirect: portfolio_45partnersEVPgULgvniMeta?.redirect,
    component: () => import("/codebuild/output/src2698016701/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-partners.vue").then(m => m.default || m)
  },
  {
    name: privacyhC7eVhJHReMeta?.name ?? "privacy",
    path: privacyhC7eVhJHReMeta?.path ?? "/privacy",
    meta: privacyhC7eVhJHReMeta || {},
    alias: privacyhC7eVhJHReMeta?.alias || [],
    redirect: privacyhC7eVhJHReMeta?.redirect,
    component: () => import("/codebuild/output/src2698016701/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/privacy.vue").then(m => m.default || m)
  }
]